<template>
  <div class="listing-search-criteria">
    <v-container fluid class="pt-0">
      <v-row>
        <v-col md="8">
          <p class="m-0 mb-2 font-size-16 font-weight-600">Search Criteria</p>
        </v-col>
        <v-col md="4" class="text-right">
          <div class="close-btn">
            <v-btn
              fab
              outlined
              x-small
              :disabled="dataLoading"
              elevation="2"
              hide-details
              dense
              color="red lighten-1"
              class="red--background"
              v-on:click="clearSearch"
            >
              <v-icon dark class="text-white"> mdi-close </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <ul>
        <template v-if="searchParam">
          <template v-for="(bparam, bindex) in basicSearchFields">
            <li :key="bindex" class="font-weight-500 font-size-14">
              {{ lodash.capitalize(lodash.lowerCase(bparam)) }} contains
              <span class="font-weight-700">{{ searchParam }}</span> (or)
            </li>
          </template>
        </template>
        <template v-else>
          <template v-for="(param, index) in listingSearch">
            <li v-if="param" :key="index" class="font-weight-500 font-size-14">
              <template v-if="typeof param == 'object'"
                >{{ lodash.capitalize(lodash.lowerCase(index)) }} between
              </template>
              <template v-if="typeof param == 'string'"
                >{{
                  lodash.capitalize(
                    lodash
                      .lowerCase(
                        lodash
                          .replace(index, "text", "name")
                          .replaceAll("[barcode]", " #")
                      )
                      .replaceAll("barcode", `${pageModule} #`)
                      .replaceAll("[", ` `)
                      .replaceAll("]", ``)
                  )
                }}
                contains
              </template>
              <span class="font-weight-700">{{ param }}</span>
              <template v-if="lodash.findLastKey(listingSearch) != index">
                (and)
              </template>
            </li>
          </template>
        </template>
      </ul>
      <v-btn
        text
        :disabled="dataLoading"
        color="cyan"
        v-on:click="advancedSearch"
      >
        Advanced Search <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { SearchEventBus } from "@/core/lib/search.lib";

export default {
  props: {
    dataLoading: {
      type: Boolean
    },
    pageModule: {
      required: true,
      type: String,
      default: () => {
        return "";
      }
    },
    basicSearchFields: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      }
    },
    allowedSearchFields: {
      type: Array,
      required: true,
      default: () => {
        return new Array();
      }
    }
  },
  data() {
    return {
      searchParam: null,
      listingSearch: new Object()
    };
  },
  watch: {
    "$route.query": {
      deep: true,
      immediate: true,
      handler() {
        this.updateFields();
      }
    }
  },
  methods: {
    clearSearch() {
      SearchEventBus.$emit("clear-search", true);
    },
    advancedSearch() {
      SearchEventBus.$emit("advanced-search", true);
    },
    updateFields() {
      const _this = this;
      _this.searchParam = null;
      _this.listingSearch = new Object();

      if (
        _this.lodash.isEmpty(_this.$route.query) === false &&
        _this.$route.query.search
      ) {
        _this.searchParam = _this.$route.query.search;
      }

      if (
        _this.lodash.isEmpty(_this.$route.query) === false &&
        !_this.$route.query.search
      ) {
        for (let key in _this.$route.query) {
          if (_this.lodash.includes(_this.allowedSearchFields, key)) {
            if (_this.lodash.isString(_this.$route.query[key])) {
              _this.listingSearch[key] = _this.$route.query[key];
            } else if (
              _this.lodash.isEmpty(_this.$route.query[key]) === false
            ) {
              _this.listingSearch[key] = _this.$route.query[key];
            }
          }
        }
      }
    }
  },
  beforeDestroy() {
    this.listingSearch = new Object();
    this.searchParam = null;
  }
};
</script>
